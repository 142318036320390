header{
    background-color: #eeebeb;
    padding: 20px;
    border-bottom: 2px solid #ffffff;
    color: #000000;
}
h1{
    font-size: 36px;
    /* font-family: Arial, sans-serif; */
    text-transform: capitalize;
    letter-spacing: 2px;
    margin: 0px;
    text-align: center;
}

.icon{
    padding: 0 5px;
}
.prjbtn{
    display: flex;
    justify-content: space-between;
}
.modal-footer {
    display: flex;
    flex-shrink: 0;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center !important;
}
.dashcontainer{
    border-radius: 10px !important;
    box-shadow:none !important;
    border: none !important;
    border-image: initial !important;
    border-radius: 11px !important;
}

.card {
  padding: 10px;
    /* width: 18rem; */
    border-radius: 11px !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .card:hover {
    transform: translateY(-5px);
  }
  
  .card-body {
    padding: 1.5rem;
  }
  
  .card-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
    text-align: start;
    text-transform: capitalize;
  }
  
  .card-text {
    color: #000000;
    font-size: 18px;
    text-align: start;
    text-transform: capitalize;
  }
  
  .btn-primary {
    background-color: #007bff;
    border-color: #007bff;
  }
  
  .btn-primary:hover {
    background-color: #0056b3;
    border-color: #0056b3;
  }
.flex{
  display: flex;
  justify-content: center;
  margin-top: 2% !important;
    margin-bottom: 2%;
}  
.flexbtn{
  display: flex;
  justify-content: center;
  margin-top: 2% !important;
  margin-bottom: 2%;

}
.modalsubbutton {
  display: inline-block !important;
  padding: 10px 20px !important;
  font-size: 16px !important ;
  text-align: center !important;
  text-decoration: none !important;
  border: 1px solid #000000 !important;
  color: #fff !important;
  background: linear-gradient(146deg, #281734, #2d1834, #464a77, #868bbd) !important; 
  border-radius: 5px !important;
  transition: background-color 0.3s, color 0.3s !important;
  margin-top: 3%;
  margin-bottom: 4%;
}
.scrollRow{
  width: 100% !important;
}
.dbutton{
  background: none !important;
    border: none !important;
    color: #000000 !important;
}
.ddrop{
  position: absolute !important;
    top: 4% !important;
    right: 3% !important;
    font-size: 41px;
}
.dropdown-toggle::after {
  display: none !important;
}


.padd{
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}


@media(max-width:767px) {

  .mobviewbtn{
    width: 166px !important;
  }

.react-datepicker {
    margin-top: 13% !important;
    margin-left: -82% !important;
  }
  .row {
    --bs-gutter-x: 0rem !important;
  }
  .fildercol {
    display: flex;
    align-items: center;
    justify-content: center;
}
.h3h3h3{
  font-size: 1.5rem !important;
}
}
.flexamount{
  display: flex;
  justify-content: space-between;
  text-align: center;
}
.card-text{
  margin: 0;
  font-size: 17px;
    font-weight: 500;
}
.expencecar{
  --bs-card-title-color: ;
  --bs-card-subtitle-color: ;
  --bs-card-border-width: var(--bs-border-width);
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: var(--bs-border-radius);
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(var(--bs-border-radius) -(var(--bs-border-width)));
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(var(--bs-body-color-rgb), 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: var(--bs-body-bg);
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  color: var(--bs-body-color);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  padding: 0px 50px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  border-radius: 5px;
}
.card-title {
  font-size: 1.2rem !important;
  font-weight: bold;
  color: #333;
  text-align: start;
  text-transform: capitalize;
  margin-top: 5px;
}
.card-text {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 5px !important;
}

.jmjbutton {
  height:89% !important;
  display: inline-block !important;
  padding: 10px 20px !important;
  font-size: 16px !important;
  text-align: center !important;
  text-decoration: none !important;
  border: 1px solid #000000 !important;
  color: #fff !important;
  background: linear-gradient(146deg, #281734, #2d1834, #464a77, #868bbd) !important;
  border-radius: 5px !important;
  transition: background-color 0.3s, color 0.3s !important;
}

.form-field {
  margin-top: 14%;
  /* width: 230px; */
  height: 47px;
  padding: 14px;
  border-radius: 8px;
  box-shadow: 0px 0px 5px 2px rgba(12, 10, 11, 0.25);
  border: none;
  color: black;
  display: flex;
}

.form-field input {
  border: none;
  outline: none;
}
.react-datepicker__month-container {
  float: left;
  border: 1px solid;
}
.fildercol{
  display: flex;
  justify-content: start;
  align-items: center;
}
.errorvalidation{
    position: absolute;
    margin-top: 44px;
  /* top: 24%; */
}
.react-datepicker {
  z-index: 9999;
  margin-left: -41%;
}
.react-datepicker__month {
  margin: 0.4rem;
  text-align: center;
  background: #fffefe;
}
.comments{
  width: 100%;
    border-radius: 8px;
    box-shadow: 0px 0px 5px 2px rgba(12, 10, 11, 0.25);
    border: none;
    padding: 10px 10px;
}



@media print {
  .print-content {
    display: block !important;
  }
}
@media screen {
  .print-content {
    display: none !important;
  }
}

@media only screen and (min-width: 300px) and (max-width: 1024px)  {
  .mblviewbutton{
    display: flex;
    justify-content: center;
    
  }
  .form-field {
    margin-top: 4% !important;
    /* width: 230px; */
    height: 47px;
    padding: 14px;
    border-radius: 8px;
    box-shadow: 0px 0px 5px 2px rgba(12, 10, 11, 0.25);
    border: none;
    color: black;
    display: flex;
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1192px)  {

.form-field {
  margin-top: 10% !important;
}
}
@media only screen and (min-width: 1180px) and (max-width: 2200px)  {
  .form-field {
    width: 240px !important;
    margin-top: 14px !important;


  }

}
@media only screen and (min-width: 300px) and (max-width: 1180px) {
  .mblcolsearch{
    display: flex;
    justify-content: center;
    margin-bottom: 5% !important;
  }
  .web_view{
    display: none !important;
  }
 
.flexbtn {
    flex: 0 0 auto !important;
    width: 50% !important;
    margin-top: 4% !important;
    margin-bottom: 2% !important;
}

.jmjbutton {
  height: auto !important;
  display: inline-block !important;
  padding: 10px 20px !important;
  font-size: 16px !important;
  text-align: center !important;
  text-decoration: none !important;
  border: 1px solid #000000 !important;
  color: #fff !important;
  background: linear-gradient(146deg, #281734, #2d1834, #464a77, #868bbd) !important;
  border-radius: 5px !important;
  transition: background-color 0.3s, color 0.3s !important;
}
.mblview{
  height: 74px;
  }
  .flexbtn1{
    display: flex;
    justify-content: center;
    margin-top: 5% !important;
  }
  .flexbtn2{
    display: flex;
    justify-content: center;
    margin-top: 5% !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .mblcolsearch{
    display: flex;
    justify-content: center;
    margin-bottom: 5% !important;
    flex: 0 0 auto;
    width: 132.333333% !important;

  }
  .mblviewbutton{
    flex: 0 0 auto;
    width: 33% !important;
  }
  .flex{
    flex: 0 0 auto !important;
    width: 50% !important; 
  }
  .mblview{
    height: 74px;
    }
    .flexbtn2{
      display: flex;
    flex: 0 0 auto !important;
    width: 10% !important;
    margin-top: 4% !important;
    margin-bottom: 2% !important;
    }
    .flexbtn1{
      display: flex;
      flex: 0 0 auto !important;
      width: 44% !important;
      margin-top: 4% !important;
      margin-bottom: 2% !important;
      justify-content: center;
    }
}
@media only screen and (min-width: 1024px) and (max-width: 1180px) {
  
  .flexbtn2{
    display: flex;
  flex: 0 0 auto !important;
  width: 10% !important;
  margin-top: 4% !important;
  margin-bottom: 2% !important;
  }
  .flexbtn1{
    display: flex;
    flex: 0 0 auto !important;
    width: 44% !important;
    margin-top: 4% !important;
    margin-bottom: 2% !important;
    justify-content: center;
  }
  .mblview{
    height: 74px;
    }
  .jmjbutton {
    height: auto !important;
    display: inline-block !important;
    padding: 10px 20px !important;
    font-size: 16px !important;
    text-align: center !important;
    text-decoration: none !important;
    border: 1px solid #000000 !important;
    color: #fff !important;
    background: linear-gradient(146deg, #281734, #2d1834, #464a77, #868bbd) !important;
    border-radius: 5px !important;
    transition: background-color 0.3s, color 0.3s !important;
  }
}
/* @media only screen and (min-width: 1024px) and (max-width: 1400px) {

  .flexbtn {
      flex: 0 0 auto !important;
      width: 50% !important;
      margin-top: 4% !important;
      margin-bottom: 2% !important;
  }
} */
@media only screen and (min-width: 1180px) and (max-width: 2200px) {  
  .mob_view{
    display: none !important;
  }
}


@media(max-width:2204px) {

  .mobviewbtn{
    width: 166px !important;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 2200px) {  

.errorvalidation{
  position: absolute;
  margin-top: auto !important;
top: 22%;
}
}


.Toolsbutton {
  margin: 15px !important;
  display: inline-block !important;
  padding: 10px 20px !important;
  font-size: 16px !important ;
  text-align: center !important;
  text-decoration: none !important;
  border: 1px solid #000000 !important;
  color: #fff !important;
  background: linear-gradient(146deg, #281734, #2d1834, #464a77, #868bbd) !important; 
  border-radius: 5px !important;
  transition: background-color 0.3s, color 0.3s !important;
}

.Toolsbutton:hover {
  color: #ffffff !important;
  background-color: #45a049 !important;
}

.Toolsbutton:active {
  background-color: #3e8e41 !important;
}

@media only screen and (min-width: 300px) and (max-width: 767px) {


.flexbtn1 {
  flex: 0 0 auto !important;
  width: 50% !important;
}
}

@media only screen and (min-width: 768px) and (max-width: 1180px) {
  .flexbtn1 {
    flex: 0 0 auto !important;
    width: 44% !important;

  }
  .flexbtn2 {
    display: flex;
    flex: 0 0 auto !important;
    width: 0% !important;
    margin-top: 45px !important;
    margin-bottom: 2% !important;
}
}
@media only screen and (min-width: 1181px) and (max-width: 2200px) {

.flexbtn2 {
margin-top: 57px !important;
}
}
@media only screen and (min-width: 320px) and (max-width: 350px) {

.expencecar {
  --bs-card-title-color: ;
  --bs-card-subtitle-color: ;
  --bs-card-border-width: var(--bs-border-width);
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: var(--bs-border-radius);
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(var(--bs-border-radius) -(var(--bs-border-width)));
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(var(--bs-body-color-rgb), 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: var(--bs-body-bg);
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  color: var(--bs-body-color);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  padding: 0px 46px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  border-radius: 5px;
}
}

@media print {
  .grid-container {
    display: grid;
    grid-template-columns: repeat(2, auto); /* Change from "auto auto" to "repeat(2, auto)" for two cards per row */
    grid-gap: 10px; /* Adjust the gap between cards if needed */
 /* justify-content: space-evenly; */
  }
  .grid-container .card {
    width: 100%; /* Adjust card width to fit two cards per row */
    margin: 0; /* Remove any margin */
  }
}

.card-title1{
  font-size: 1.1rem !important;
  font-weight: bold;
  color: #333;
  text-align: start;
  text-transform: capitalize;
  margin-top: 5px;
}


.balance-card {
  background-color: #f8f9fa;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  font-size: 22px;
  display: flex;
  justify-content: center;
}

.balance {
  color: #271932;
  font-weight: bold;
  font-size: 22px;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 104% !important;
}

