
  .icon {
    margin-right: 5px; /* Adjust icon spacing */
  }
  /* .framecard{
    max-width: 550px !important;
height: 425px !important;
  } */
  .prjcon{
    border-radius: 10px !important;
    box-shadow:none !important;
    border:none !important;
    border-image: initial;
    border-radius: 11px;
  }
  .imgcard {
    border: 1px solid #000000;
    width: 300px; 
    height: 300px; 
    background-color: #ffffff; 
    /* border-radius: 8px; */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
    overflow: hidden; 
    margin: 20px; 
  }
  .backicon{
    text-align: center;
    position: absolute;
    padding-top: 8px;
    font-size: 32px;
  }
  .colprjname{
    display: flex;
    align-items: center;
    padding: 0% 3% !important;
  }
  label{
    font-weight: 500;
    font-size: 18px;
    color: #000000;
}
.boxcardcol{
    display: flex;
    align-items: center;
    justify-content: center;
}
.labelhead{
    font-weight: 500;
    font-size: 15px;
    color: #000000;
}
.downloadicon{
  position: absolute;
  top: 4%;
  right: 8%;
  font-size: 35px;
}
.printicon{
  position: absolute;
  top: 4%;
  right: 3%; 
  font-size: 25px;
}

@media(max-width:767px) {
.downloadicon {
  position: absolute;
  top: 4%;
  right: 10%;
  font-size: 20px;
}
.backicon {
  text-align: center;
  position: absolute;
  padding-top: 5px;
  font-size: 20px;
}
.printicon{
  position: absolute;
  top: 4%;
  right: 0%;
  font-size: 20px;

}
.heading{
  font-size: 20px;
  padding-top: 5px;
  text-align: center;
}
.boxcardcol {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
label {
  margin-top: 0px !important;
  padding-top: 0px !important;
  font-weight: 500;
  font-size: 16px;
  color: #000000;
  margin-bottom: 10px;
}
}



@media only screen and (min-width: 390px) and (max-width: 500px) {
  .downloadicon {
    position: absolute;
    top: 3% !important;
    right: 9% !important;
    font-size: 20px;
}
.printicon {
  position: absolute;
  top: 3%;
  right: 0%;
  font-size: 20px;
}

}
@media only screen and (min-width: 768px) and (max-width: 1124px) {
.downloadicon {
  position: absolute;
  top: 3%;
  right: 11%;
  font-size: 35px;
}
.printicon {
  position: absolute;
  top: 2% !important;
  right: 3%;
  font-size: 35px;
}
.table-responsive-md {
  overflow-x: auto !important;
  -webkit-overflow-scrolling: touch;
}
}
@media only screen and (min-width: 900px) and (max-width: 1024px) {

.downloadicon {
  position: absolute;
  top: 2% !important;
  right: 11%;
  font-size: 35px;
}
.printicon {
  position: absolute;
  top: 2% !important;
  right: 3%;
  font-size: 35px;
}
}
@media only screen and (min-width: 1024px) and (max-width: 1200px) {

.printicon {
  position: absolute;
  top: 2% !important;
  right: 3%;
  font-size: 35px;
}
}
.actionbutton {
  display: inline-block !important;
  padding: 0px;
  font-size: 16px !important;
  text-align: center !important;
  text-decoration: none !important;
  border: 1px solid #000000 !important;
  color: #fff !important;
  background: linear-gradient(146deg, #281734, #2d1834, #464a77, #868bbd) !important;
  border-radius: 5px !important;
  transition: background-color 0.3s, color 0.3s !important;
}
.tablepara{
  padding: 10px;
  border: 1.8px solid #f2f2f2;
  margin-bottom: 0px;
  text-align: center;
  font-size: 18px;
  font-weight: 500;

}
.text-danger {
  --bs-text-opacity: 1;
  font-size: 16px;
  /* text-transform: capitalize; */
  color: red !important;
}

@media print {
  body * {
    visibility: hidden;
  }
  #pdf-content, #pdf-content * {
    visibility: visible;
  }
}

.svgletter{
  text-align: center;
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}


.custom-underline {
  display: inline;
  position: relative;
}

.custom-underline::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px; /* Adjust this value to control the distance of the underline from the text */
  width: 100%;
  border-bottom: 2px solid #000; /* Change color as needed */
}

.svgtext{
  font-size: 20px;
  font-weight: bolder;
}

@media print {
  body * {
    visibility: visible;
  }
}


.print-container {
  display: block;
}

.print-row {
  display: flex;
  flex-wrap: wrap;
}

.print-row > .col {
  flex: 1;
  max-width: 33.333%;
}



@media print {
  .single-column {
    column-count: 1;
  }
  .page {
    break-inside: avoid;
  }


  .grid-container {
    display: grid;
    grid-template-columns: auto auto auto;
  }
  .grid-item {
    background-color: rgba(255, 255, 255, 0.8);
    font-size: 20px;
    /* margin-top: 5%; */
    /* text-align: center; */

  }
  /* .paddingpdf{
  padding-top: 5%;
  padding-bottom: 5%;
  }
  .paddingpdfh3{
    padding-top: 0%;
  } */
  .paddingpdfh3{
    /* padding-top:5px; */
  padding-bottom: 7px; 
  }
  .allsvgdiv{
    /* width: 500px !important; */
    /* height: 600px !important; */
  }
}

@media print {
  .print-content {
    display: block !important;
  }
}
@media screen {
  .print-content {
    display: none;
  }
}

.downexcel{
  padding-left: 10%;
  color: #000000 !important;
  font-size: 22px;
    vertical-align: baseline;
}

