body{
    font-family: ui-sans-serif;
}
.hello{
    text-align: center;
    font-size: 22px;
    color: blue;
}

.main {
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f7f9;
    color: #12344d;
    display: flex;
}

.container{
border-radius: 10px;
box-shadow: 0 5px 10px 0 rgba(0,0,0,.1);
border: 1px solid #e6e6e6;
border-image: initial;
border-radius: 11px;
}
.label{
    padding-bottom: 10px;
font-size: 16px;
font-weight: 600;
}
.labeldiv{
    text-align: start;
    padding-bottom: 5%;
}
.forgot{
    font-size: 18px;
    padding: 2%;
    font-family: ui-sans-serif;
}
.forgot a{
    text-decoration: auto;
}
.colbg{
    background: linear-gradient(146deg, #281734, #2d1834, #464a77, #868bbd);
}
.img-fluid {
    max-width: 100%;
    height: auto;
 }

 .subbutton {
    display: inline-block !important;
    padding: 10px 20px !important;
    font-size: 16px !important ;
    text-align: center !important;
    text-decoration: none !important;
    border: 1px solid #000000 !important;
    color: #fff !important;
    background: linear-gradient(146deg, #281734, #2d1834, #464a77, #868bbd) !important; 
    border-radius: 5px !important;
    transition: background-color 0.3s, color 0.3s !important;
  }
  
  .subbutton:hover {
    color: #ffffff !important;
    background-color: #45a049 !important;
  }
  
  .subbutton:active {
    background-color: #3e8e41 !important;
  }
  .loginform{
    padding: 0% 10%;
  }
  .centercol{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .container{
    max-width: 1140px !important;
  }

@media(max-width:767px) {
  .main {
    justify-content: center;
    align-items: center;
    height: auto !important;
    background-color: #f5f7f9;
    color: #12344d;
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
}

.position {
  position: absolute;
  top: 86% !important;
  right: 22%;
}

}


@media only screen and (min-width: 360px) and (max-width: 400px) {
  .eyeicon {
    margin-left: -16% !important; 
  }
  }
.newbtn{
  margin: 10% !important;
}



.form-login {
  background: #ffffff;
  /* width: 420px; */
  height: 45px;
  padding: 14px;
  border-radius: 8px;
  box-shadow: 0px 0px 5px 2px rgba(12, 10, 11, 0.25);
  border: none;
  color: black;
  display: flex;
  justify-content: space-between;

}
.form-login input {
  border: none;
  outline: none;
  font-size: 18px;
  padding-bottom: 4px;
  width: 100%;
  height: 22px
}